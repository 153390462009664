<template>
  <footer class="contain-content b-t b-grey-50 bg-black c-white content-visibility-auto" :class="brandClasses?.root">
    <div class="mx-a divide-y divide-grey-50" style="max-width: 90rem">
      <form-newsletter v-if="!hideNewsletter" variant="inverse" class="mx-a py-8 md:w-112 <md:px-4" />
      <vf-footer-columns :footer-data="data" />
      <div class="center md:mx-4 md:flex md:divide-x divide-grey-50 <md:divide-y">
        <section class="f-col items-start px-4 between md:my-8 md:px-16 <md:py-8">
          <div>
            <h2 class="mb-2 subtitle-3">
              {{ $t.footerContent.storeLocator.header }}
            </h2>
            <p class="mb-6 text-sm md:mb-4">
              {{ $t.footerContent.storeLocator.description }}
            </p>
          </div>
          <base-link
            class="vf-button-xs <md:w-full vf-button-primary-inverse vf-button"
            to="/store-locator"
            @click="$gtm.push('footer.onClickFooterLink', 'Store Locator')"
          >
            {{ $t.footerContent.storeLocator.button }}
          </base-link>
        </section>

        <section class="items-start px-4 between md:my-8 md:px-16 <md:py-8">
          <div>
            <h2 class="mb-2 subtitle-3">
              {{ $t.footerContent.social.header }}
            </h2>
            <div class="flex space-x-4 md:space-x-2">
              <div v-for="(to, name) in data?.links?.social" :key="name">
                <base-link :to :aria-label="name">
                  <vf-icon :name size="lg" />
                </base-link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <vf-footer-legal-links v-if="data?.links?.legal" :links="data.links.legal">
        <base-link to="/" class="<md:hidden">
          <vf-logo style="max-width: 7.5rem" variant="full" invert loading="lazy" />
        </base-link>
        <template v-if="data.paymentCardTypes" #right>
          <vf-footer-payments :card-types="data.paymentCardTypes" />
        </template>
      </vf-footer-legal-links>
    </div>
  </footer>
</template>

<script lang="ts" setup>
defineProps<{
  hideNewsletter?: boolean
}>()
const { brandClasses } = useAppConfig().components.vf.footer || {}
const { data } = await useApi().internal.getFooter()
</script>
