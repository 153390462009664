<template>
  <div class="center self-start gap-2 b b-white bg-white p-1 i-flex">
    <base-picture
      v-for="{ type, picture } in cardPayments"
      :key="type"
      :alt="type"
      :src="picture"
      class="h-5"
      height="20"
      width="36"
      :props-img="{ class: 'w-a' }"
    />
    <!-- Paypal -->
    <base-picture
      v-if="$feature.allowPayPalAsPayment"
      src="/img/logos/paypal.svg"
      alt="Paypal"
      class="h-5"
      height="20"
      width="82"
    />
    <!-- Apple Pay -->
    <base-picture
      v-if="$feature.allowApplePayAsPayment"
      src="/img/logos/apple-pay.svg"
      :alt="$t.applePayIconText"
      class="h-5"
      height="20"
      width="47"
    />
    <!-- Klarna -->
    <base-picture
      v-if="$feature.allowKlarnaAsPayment"
      src="/img/logos/klarna-pink.svg"
      alt="Klarna"
      class="h-5"
      height="20"
      width="50"
    />
  </div>
</template>

<script lang="ts" setup>
const { cardTypes } = defineProps<{
  /**
   * available CreditCardTypes
   */
  cardTypes: string[]
}>()

const cardPayments = cardTypes.map((cardType) => ({ type: cardType, picture: getCreditCardImage(cardType) || '' }))
</script>
